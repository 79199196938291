<template>
  <el-container>
    <el-main>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="打印设备" name="Device">
            <Device :printerInfo="printerInfo" @handle-input="handleInput"></Device>
        </el-tab-pane>
        <el-tab-pane label="打印模板设置" name="Templatesetting">
            <Templatesetting :printerInfo="printerInfo"  @handle-input="handleInput"></Templatesetting>
        </el-tab-pane>
        <el-tab-pane label="打印规则设置" name="Rulesetting">
            <Rulesetting :printerInfo="printerInfo"  @handle-input="handleInput"></Rulesetting>
        </el-tab-pane>
      </el-tabs>
      <Preservation @preservation="preservation" ></Preservation>
    </el-main>
  </el-container>
</template>

<script>
import Preservation from '@/components/preservation'
import Device from './components/device.vue'
import Templatesetting from './components/tsetting.vue'
import Rulesetting from './components/rulesetting.vue'
export default {
    components:{
        Device,
        Templatesetting,
        Rulesetting,
        Preservation
    },
  data() {
    return {
        activeName:"Device",
        printerInfo:null
    };
  },
  created() {
      this.getPrinterInfo()
  },
  methods: {
      getPrinterInfo(){
        this.$axios.post(this.$api.cashier.CashierPrinterConfig)
        .then(res => {
          if (res.code == 0) {
            this.printerInfo = res.result
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      preservation(){
        this.$axios.post(this.$api.cashier.CashierPrinterEdit,this.printerInfo)
        .then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
             this.getPrinterInfo()
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      handleInput(obj){
        this.printerInfo[obj.attr] = obj.val
      }
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}
.el-main {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
}
</style>
