<template>
  <div>
       <el-form ref="form" label-width="160px">
            <el-form-item label="打印张数：" required >
                <el-input v-model="form_num" class="inputClass" @input="handleInput($event,'print_quantity')" ></el-input>
            </el-form-item>

        <el-form-item label="打印设备：" prop="code" required>
            <!-- <el-link type="primary" :underline="false">打印设备管理</el-link> -->
             <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" class="table" >
                <template slot="empty">
                    <div class="empty">没有检测到打印机，请添加打印机</div>
                </template>
                <el-table-column prop="name" label="设备备注名称" align="center">
                    <template slot-scope="scope">
                        <div class="flex">
                            <i class="el-icon-printer"></i>
                            <span>{{scope.row.name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="连接状态" align="center">
                     <template slot-scope="scope">
                         <i :class="[scope.row.state.indexOf('离线')==-1?'el-icon-success':'el-icon-error']"></i>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" :type="scope.row.state.indexOf('离线')==-1?'info':'primary'" disabled>{{scope.row.state.indexOf('离线')==-1?'断开':'连接'}}</el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    props:['printerInfo'],
    data(){
        return {
            form_num:1,
            list:[],
        }
    },
    methods:{
        // changeState(item){
        //     item.state = !item.state
        // }
        handleInput(val,attr){
            this.$emit('handle-input',{
                attr,val
            })
        }
    },
      watch:{
        printerInfo(val){
            this.form_num = val.print_quantity
            this.list = []
            this.list.push({
                name:val.remark,
                state:val.data.data
            })
        }
      }
}
</script>

<style scoped lang="less">
    .inputClass{
        width: 700px;
    }
    .table{
        width: 700px;
    }
    .empty{
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-form-item{
        margin-bottom: 22px!important;
    }
    .el-icon-printer{
        font-size: 20px;
        color:#333333;
        margin-right:5px;
    }
    .el-icon-error{
        font-size: 20px;
        color:#EA4335;
    }
    .el-icon-success{
         font-size: 20px;
         color:#409EFF;
    }
    .flex{
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
