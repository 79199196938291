<template>
    <div class="tsetting" >
        <div class="left">
            <el-form ref="form" :model="form" label-width="160px" :rules="rules">
                <el-form-item label="编辑购物小票模板：">
                    <div>日期、单号（小票必须展示的信息）</div>
                </el-form-item>
                <el-form-item label="选填项：">
                    <el-checkbox-group v-model="form.checkList">
                        <el-checkbox label="is_print_store_name" @change="handleInput($event?1:0,'is_print_store_name')">店铺名称</el-checkbox>
                        <el-checkbox label="is_print_member_card" @change="handleInput($event?1:0,'is_print_member_card')">会员卡号</el-checkbox>
                        <el-checkbox label="is_print_user_name" @change="handleInput($event?1:0,'is_print_user_name')">会员姓名</el-checkbox>
                        <el-checkbox label="is_print_mobile" @change="handleInput($event?1:0,'is_print_mobile')">会员手机</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="店铺名称：" >
                    <el-radio-group v-model="form.storename_radio" @change="handleInput($event,'type')" >
                        <el-radio :label="0">默认店铺名称</el-radio>
                        <el-radio :label="1">自定义店铺名称</el-radio>
                    </el-radio-group>
                    <div v-if="form.storename_radio==1">
                        <el-input v-model="form.store_name" class="inputClass" @input="handleInput($event,'store_name')"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="脱敏信息：">
                    <el-checkbox-group v-model="form.checkList2" >
                        <el-checkbox label="is_sensitive_member_card" @change="handleInput($event?1:0,'is_sensitive_member_card')">会员卡号</el-checkbox>
                        <el-checkbox label="is_sensitive_user_name" @change="handleInput($event?1:0,'is_sensitive_user_name')">会员姓名</el-checkbox>
                        <el-checkbox label="is_sensitive_mobile" @change="handleInput($event?1:0,'is_sensitive_mobile')">会员手机</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <div class="ticket">
                <div class="store_name" v-if="form.checkList.indexOf('is_print_store_name')!=-1">{{form.store_name}}</div>
                <div class="row row1">日期：2021-04-12 12:20:21</div>
                <div class="row">单号：#################</div>
                <div class="row2">
                    <span class="item1">商品名称</span>
                    <span class="item2">单价</span>
                    <span class="item2">数量</span>
                    <span class="item2">金额</span>
                </div>
                <div class="row2 row3">
                    <span class="item1">#####/规格：#</span>
                    <span class="item2">#</span>
                    <span class="item2">#</span>
                    <span class="item2">#</span>
                </div>
                <div class="row4">
                    <div class="item3">订单总价：#######</div>
                    <div class="item3">优惠金额：#######</div>
                    <div class="item3">会员折扣金额：#######</div>
                    <div class="item3">会员余额抵扣金额：#######</div>
                    <div class="item3">实付金额：#######</div>
                    <div class="item3">支付方式：#######</div>
                </div>
                <div class="row5">
                    <div class="item3" v-if="form.checkList.indexOf('is_print_member_card')!=-1">会员卡号：#######</div>
                    <div class="item3" v-if="form.checkList.indexOf('is_print_user_name')!=-1">会员姓名：#######</div>
                    <div class="item3" v-if="form.checkList.indexOf('is_print_mobile')!=-1">会员手机：#######</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['printerInfo'],
    data(){
        return {
            form:{
                checkList: [],
                storename_radio:1,
                store_name:"",
                checkList2:[]
            },

        }
    },
     watch:{
        printerInfo(val){
            let checkList = []
            let attr = ['is_print_store_name','is_print_member_card','is_print_user_name','is_print_mobile']
            attr.forEach(item=>{
                if(val[item]==1){
                    checkList.push(item)
                }
            })
            let checkList2 = []
            let attr2 = ['is_sensitive_member_card','is_sensitive_user_name','is_sensitive_mobile']
             attr2.forEach(item=>{
                if(val[item]==1){
                    checkList2.push(item)
                }
            })
            this.form = {
                checkList:checkList,
                storename_radio:val.type,
                store_name:val.store_name,
                checkList2:checkList2
            }
        }
    },
    methods:{
        handleInput(val,attr){
            this.$emit('handle-input',{
                attr,val
            })
        }
    },
    created(){
        if(this.printerInfo){
            let checkList = []
            let val = this.printerInfo
            let attr = ['is_print_store_name','is_print_member_card','is_print_user_name','is_print_mobile']
            attr.forEach(item=>{
                if(val[item]==1){
                    checkList.push(item)
                }
            })
            let checkList2 = []
            let attr2 = ['is_sensitive_member_card','is_sensitive_user_name','is_sensitive_mobile']
             attr2.forEach(item=>{
                if(val[item]==1){
                    checkList2.push(item)
                }
            })
            this.form = {
                checkList:checkList,
                storename_radio:val.type,
                store_name:val.store_name,
                checkList2:checkList2
            }
        }
    }
}
</script>

<style scoped lang="less">
.tsetting{
    display: flex;
    justify-content: space-between;
}
.left,.right{
    flex:1;
}
.inputClass{
    width: 460px;
}
.ticket{
    margin: 0 auto;
    width: 260px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    padding: 42px 15px 26px;
    font-size: 13px;
    color: #555;
    overflow: hidden;
    .store_name{
        text-align: center;
        font-size: 24px;
    }
    .row{
        margin-bottom: 6px;
    }
    .row1{
        margin-top: 14px;
    }
    .row2{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #ddd;
        padding-bottom: 10px;
        .item1{
            flex: 2;
        }
        .item2{
            flex: 1;
            flex-shrink: 0;
        }
    }
    .row3{
        padding-top: 10px;
    }
    .row4{
        padding: 10px 0;
        border-bottom: 1px dashed #ddd;
    }
    .row5{
        padding-top: 10px;
    }
    .item3{
        line-height: 18px;
    }
}

</style>
